<template>
  <router-view></router-view>
</template>
<script>

export default {
  name: 'MyNews',
  data() {
    return {

    }
  },
  mounted() {
    // console.log(this.$refs.content.offsetHeight)
    // this.mainHei = this.$refs.content.offsetHeight - 60 + 'px'
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>

</style>
